<template>
  <div>
    <div v-if="!confirmationShow" class="information-form">
      <el-form ref="information" size="small" label-position="top" :model="formData" label-width="80px">
        <div class="item-title">证书信息</div>
        <el-table class="info-table" :data="[info]" border style="width: 100%">
          <el-table-column prop="certSn" label="证书序列号" width="220">
          </el-table-column>
          <el-table-column prop="orgInfoEntity.organization" label="所属机构/O">
          </el-table-column>
          <el-table-column prop="orgInfoEntity.organizationalUnit" label="部门/OU">
          </el-table-column>
          <template v-if="info.certType!== 2">
            <el-table-column prop="personInfoEntity.name" label="证书名称/CN">
            </el-table-column>
            <el-table-column label="证件类型">
              <template slot-scope="scope">
                {{ IdentType(scope.row.personInfoEntity.identType) }}
              </template>
            </el-table-column>
            <el-table-column prop="personInfoEntity.identNo" label="证件号码">
              <template slot-scope="scope">
                {{ $util.IdNoSecret(scope.row.personInfoEntity.identNo) }}
              </template>
            </el-table-column>
          </template>
          <template v-else>
            <el-table-column prop="orgInfoEntity.organization" label="证书名称/CN">
            </el-table-column>
            <el-table-column label="证件类型">
              <template slot-scope="scope">
                {{ IdentType(scope.row.orgInfoEntity.orgIdentType) }}
              </template>
            </el-table-column>
            <el-table-column prop="orgInfoEntity.orgIdentNo" label="证件号码">
            </el-table-column>
          </template>
          <el-table-column label="剩余有效时间" width="200">
            <template slot-scope="scope">
              <div style="color:#FF443E;">
                {{ `${indate(scope.row.certEndTime)}（${scope.row.certEndTime.split(' ')[0]}到期)` }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="item-title" v-if="info.sealInfoEntitys">印章图样</div>
        <div class="commonPatternBig" v-if="info.sealInfoEntitys">
          <img :src="info.sealInfoEntitys[0].patternFilePath">
        </div>

        <div class="item-title">经办人基本信息<span class="hint">（经办人手机号用于接收证书密码，业务验证码，证书解锁信息等）</span></div>
        <div class="item-box">
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人姓名">
            <el-input disabled v-model="agent.name"></el-input>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人身份证号">
            <el-input disabled v-model="agent.identNo"></el-input>
          </el-form-item>
          <el-form-item :rules="[{ required: true, message: '', trigger: 'blur' }]" label="经办人手机号">
            <el-input disabled v-model="agent.mobile"></el-input>
          </el-form-item>
        </div>

        <div class="item-title">续期信息</div>
        <el-form-item :rules="[{ required: true, message: '请选择续期年限', trigger: 'change' }]" class="label-position-left" label="续期年限" prop="certDuration">
          <el-radio-group v-model="formData.certDuration">
            <el-radio v-for="(item,index) in getYears(info.postponeYears)" :key="index" :label="Number(item)*365">{{item+'年'}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button class="submitButn" @click="nextStep" type="primary">下一步</el-button>
    </div>
    <div v-else class="confirmation">
      <div class="item-title">证书信息</div>
      <div class="item-box">
        <div>证书序列号：<span>{{info.certSn}}</span></div>
        <div>所属机构/O：<span>{{info.orgInfoEntity.organization}}</span></div>
        <div>所属部门/OU：<span>{{info.orgInfoEntity.organizationalUnit}}</span></div>
        <template v-if="info.certType!== 2">
          <div>姓名/CN：<span>{{info.personInfoEntity.name}}</span></div>
          <div>证件类型：<span>{{IdentType(info.personInfoEntity.identType)}}</span></div>
          <div>证件号码：<span>{{ $util.IdNoSecret(info.personInfoEntity.identNo)}}</span></div>
        </template>
        <template v-else>
          <div>证书名称/CN：<span>{{info.orgInfoEntity.organization}}</span></div>
          <div>证件类型：<span>{{IdentType(info.orgInfoEntity.orgIdentType)}}</span></div>
          <div>证件号码：<span>{{info.orgInfoEntity.orgIdentNo}}</span></div>
        </template>

        <div>剩余有效时间：<span> {{ `${indate(info.certEndTime)}（${info.certEndTime.split(' ')[0]}到期)` }}</span></div>
      </div>
      <div class="item-title" v-if="info.sealInfoEntitys ">印章图样</div>
      <div class="commonPatternBig" v-if="info.sealInfoEntitys ">
        <img :src="info.sealInfoEntitys[0].patternFilePath">
      </div>

      <div class="item-title">续期信息</div>
      <div class="item-box">
        <div>续期年限：<span>{{formData.certDuration/365}}年</span></div>
      </div>
      <div class="item-title">经办人信息</div>
      <div class="item-box">
        <div>经办人姓名：<span>{{agent.name}}</span></div>
        <div>经办人身份证号：<span>{{agent.identNo}}</span></div>
        <div>经办手机号：<span>{{agent.mobile}}</span></div>
      </div>
      <div class="buttons">
        <el-button @click="operationSubmit" type="primary">确认业务单</el-button>
        <el-button plain @click="confirmationShow=false">返回修改</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { IdentType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data () {
    return {
      confirmationShow: false,
      IdentType,
      agent: {},
      formData: {
        businessType: 3,
        certDuration: '',
        certSn: ''
      }
    }
  },
  created () {
    // children label value
    this.$api.getAccountInfo().then(res => {
      let { name, identNo, mobile } = res.data
      this.agent = { name, identNo, mobile }
    })
  },
  methods: {
    operationSubmit () {
      this.formData.certSn = this.info.certSn
      let transId = this.$route.query.transId
      if (transId) {
        this.formData['transId'] = transId
      }
      this.$api.operationSubmit(this.formData).then(res => {
        if (res.code === '0') {
          this.$parent.transId = res.data.id
          this.$router.push({
            query: { transId: res.data.id }
          })
        } else if (res.code === '306') {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '继续办理',
            type: 'warning'
          }).then(() => {
            this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
          })
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => { }
          })
        }
      })
    },
    nextStep () {
      this.$refs.information.validate(valid => {
        if (valid) {
          this.confirmationShow = true
        }
      })
    },
    indate (time) {
      let startTime = new Date().getTime()
      let endTime = new Date(time.replace(/-/g, '/')).getTime()
      let day = Math.floor((endTime - startTime) / (1000 * 3600 * 24))
      let years = Math.floor(Math.abs(day) / 365)
      return `${years ? years + '年' : ''}${day % 365}天`
    },
    getYears (years) {
      let arr = []
      if (years) {
        arr = years.split(',')
      }
      return arr
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.information-form {
  text-align: center;
  padding: 16px 24px;
  ::v-deep.el-form {
    text-align: left;
    .el-radio {
      margin: 10px 20px 10px 0px;
    }
    .item-box {
      display: flex;
      flex-wrap: wrap;
      .el-form-item:nth-child(3n) {
        margin-right: 0;
      }
      .el-form-item {
        width: 368px;
        margin-right: 73px;
      }
    }
    .el-form-item {
      margin-bottom: 32px;
      width: 809px;
    }
    .el-form-item__label {
      line-height: 19px;
      margin-bottom: 14px;
      font-size: 14px;
      padding: 0;
      color: #303b50;
    }
    .label-position-left {
      width: auto;
      .el-form-item__label {
        float: left;
        width: 94px;
        line-height: 32px;
        margin-bottom: 0;
      }
      .el-form-item__content {
        margin-left: 94px;
      }
    }
  }
  ::v-deep.info-table {
    margin-bottom: 32px;
    .el-table__header {
      width: 100% !important;
    }
    .el-table__body {
      width: 100% !important;
    }
    thead {
      color: #303b50;

      .cell {
        font-weight: normal;
      }
    }
    .el-table__row {
      td {
        height: 80px;
        color: #5c6269;
      }
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #fff;
    }
  }
  .submitButn {
    width: 182px;
    margin: 24px auto;
  }
}
.confirmation {
  padding: 16px 24px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .buttons {
    text-align: center;
    margin: 24px auto;
    .el-button {
      width: 182px;
    }
  }
}
</style>