<template>
  <div class="guide">
    <div class="title">续期指南</div>
    <div class="listMain">
      <div class="list-title">注意事项</div>
      <ol>
        <li>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</li>
        <li>（2）将需要续期的Ukey插入电脑USB接口，点击【证书续期申请】按钮，输入Ukey密码进入续期页面（若遇到控件拦截，需要全部运行控件）</li>
        <li>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </li>
        <li class="other"><em class=" el-icon-warning"></em> 续期请插入一个Ukey操作，以免造成数据冲突，导致续期失败。 </li>
      </ol>
    </div>
    <div class="listMain">
      <div class="list-title">续期原则</div>
      <p class="other">一、证书有效期<span>小于60天</span>或<span>已过期且不超过1年</span>（365天）的用户插入Ukey可申请续期业务；</p>
      <p>二、<span>过期超过1年</span>（365天）未续期的用户，需要<span>联系客服</span>（400-030-2013）进行续期；</p>
      <p>三、<span>未过期</span>的证书进行续期，<span>新证书有效期</span>在<span>旧证书截止日期</span>的基础上顺延；</p>
      <p>四、<span>已过期</span>的证书进行续期，<span>新证书有效期</span>在<span>下载新证书之日</span>起计算，向后顺延；</p>
    </div>
    <div class="btn">
      <el-button type="primary" @click="certRenewal">续期申请</el-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'guide',
  data () {
    return {
      projectList: []
    }
  },
  created () { },
  methods: {
    certRenewal () {
      this.$parent.Ukey.connect()
        .then(certSn => {
          this.$api.getCertBySn({ certSn, transType: 3 }).then(res => {
            if (res.code === '0') {
              this.$parent.getTransNode(certSn)
              this.$parent.certInfo = { ...res.data, certSn }
              this.$parent.agreementShow = true
            } else if (res.code === '306') {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '继续办理',
                type: 'warning'
              }).then(() => {
                this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
              })
            } else {
              this.$alert(res.msg, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
                callback: () => { }
              })
            }
          })
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.guide {
  background-color: #fff;
  padding: 0 24px;
  .title {
    font-size: 16px;
    color: #303b50;
    line-height: 52px;
    border-bottom: 1px #dee2e8 solid;
    margin-bottom: 32px;
  }
  .listMain {
    padding-left: 44px;
    padding-bottom: 40px;
    .list-title {
      color: #303b50;
      font-size: 14px;
      position: relative;
      height: 19px;
      line-height: 19px;
      margin-bottom: 24px;
      &::before {
        content: '';
        position: absolute;
        left: -28px;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background: linear-gradient(180deg, #f4f4fd 0%, #819ae7 100%);
      }
    }
    ul {
      list-style-type: none;
      display: flex;
      justify-content: flex-start;
    }
    ul > li {
      width: 97px;
      height: 42px;
      line-height: 37px;
      font-size: 12px;
      color: #303b50;
      position: relative;
      z-index: 1;
      border: 1px solid #dae1f4;
      border-left-width: 4px;
      border-bottom-width: 4px;
      margin-right: 48px;
      border-radius: 2px;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        right: -48px;
        top: 20px;
        display: inline-block;
        width: 48px;
        height: 1px;
        background: #dae1f4;
      }
      &:last-of-type::after {
        width: 0px;
      }
    }
    ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    ol > li {
      color: #303b50;
      font-size: 14px;
      position: relative;
      line-height: 14px;
      margin-bottom: 24px;
    }
    li.other {
      color: #ffb500;
      margin-bottom: 0;
    }
    .means {
      color: #e02020;
      font-size: 12px;
      line-height: 16px;
      padding-bottom: 2px;
      &.other {
        padding-top: 32px;
      }
    }
    p {
      color: #303b50;
      font-size: 12px;
      line-height: 16px;
      padding-top: 22px;
      &.other {
        padding-top: 0px;
      }
    }
    span {
      color: #ff5846;
    }
    .blue {
      color: #1c67f3;
    }
  }
  .btn {
    text-align: center;
    padding: 20px 0 48px;
    .el-button {
      padding: 12px 65px;
    }
  }
}
</style>