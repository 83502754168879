<template>
  <div class="renewalUkey">
    <div class="renewalMatter" v-if="!renewaling">
      <div class="item-title">在线续期注意事项</div>
      <p>（1）确认是否下载并安装【豸信CA数字证书助手驱动】。如“未安装”，请“<a class="blue" @click="$util.downloadById(CONFIG.APPID)">下载安装</a>”。（安装完成后请刷新页面）</p>
      <p>（2）将需要续期的Ukey插入电脑USB接口，Ukey密码验证后进行续期。（若遇到控件拦截，需要全部运行控件）</p>
      <p>（3）推荐使用【IE】、【Chrome】、【Edge】、【360】浏览器。 </p>
      <p><span class="el-icon-warning"></span> 续期请插入一个Ukey操作，以免造成数据冲突，造成续期失败。</p>
    </div>
    <template v-if="!renewaling">
      <div class="item-title">续期信息</div>
      <div class="item-box" v-if="info.applyFormEntity&&info.applyFormEntity.orgInfoEntity">
        <div>证书序列号：<span>{{info.applyFormEntity.certSn}}</span></div>
        <div>所属机构/O：<span>{{info.applyFormEntity.orgInfoEntity.organization}}</span></div>
        <div>所属部门/OU：<span>{{info.applyFormEntity.orgInfoEntity.organizationalUnit}}</span></div>
        <template v-if="info.applyFormEntity.certType!== 2">
          <div>姓名/CN：<span>{{info.applyFormEntity.personInfoEntity.name}}</span></div>
          <div>证件类型：<span>{{IdentType(info.applyFormEntity.personInfoEntity.identType)}}</span></div>
          <div>证件号码：<span>{{ $util.IdNoSecret(info.applyFormEntity.personInfoEntity.identNo)}}</span></div>
        </template>
        <template v-else>
          <div>证书名称/CN：<span>{{info.applyFormEntity.orgInfoEntity.organization}}</span></div>
          <div>证件类型：<span>{{IdentType(info.applyFormEntity.orgInfoEntity.orgIdentType)}}</span></div>
          <div>证件号码：<span>{{$util.IdNoSecret(info.applyFormEntity.orgInfoEntity.orgIdentNo)}}</span></div>
        </template>
        <div>续期年限：<span>{{info.applyFormEntity.certDuration/365}}年</span></div>
        <p class="commonPattern" v-if="info.applyFormEntity.sealInfoEntitys">印章图样：<img :src="info.applyFormEntity.sealInfoEntitys[0].patternFilePath"></p>
      </div>
      <el-button @click="connectUkey" type="primary">确定续期</el-button>
    </template>
    <div class="renewaling" v-else>
      <div class="icon">
        <img src="@/assets/icons/service/loading-icon.png" alt="">
      </div>
      <div class="tt">证书更新中</div>
      <div class="hint">提示：请不要关闭浏览器，不要拔出Ukey，在此界面等待更新完毕，以免造成证书更新失败</div>
    </div>
  </div>
</template>

<script>
import { IdentType } from '@/utils/typeMatch'
export default {
  props: ['info'],
  data () {
    return {
      IdentType,
      renewaling: false,
      transId: '',
      isFail: ''
    }
  },
  created () {
    this.transId = this.$route.query.transId
  },
  methods: {
    connectUkey () {
      this.$parent.Ukey.connect()
        .then(certSn => {
          if (certSn.toLowerCase() !== this.info.applyFormEntity.certSn.toLowerCase()) {
            this.$alert(
              '当前插入Ukey非办理续期业务所使用的，请更换Ukey',
              '提示',
              {
                confirmButtonText: '我知道了'
              }
            )
          } else {
            this.$parent.certInfo = { certSn }
            this.$parent.ukeyLogined = () => {
              this.renewaling = true
              this.getPostponeCert()
            }
            this.$parent.ukeyLoginShow = true
          }
        })
        .catch(err => {
          if (err.message) this.$message.error(err.message)
        })
    },
    getPostponeCert () {
      this.$api
        .getPostponeCert({
          transId: this.transId
        })
        .then(res => {
          this.certData = res.data
          this.$parent.Ukey.writeCert(this.certData)
            .then(res => {
              if (res) {
                this.$parent.getTransactionDetail()
              } else {
                this.$parent.transactionDetail.transactionEntity.status = 14
              }
            })
            .catch(err => {
              this.$parent.transactionDetail.transactionEntity.status = 14
              this.$message.error(err.message)
            })
        })
        .catch(() => {
          this.renewaling = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes loading {
  // 0% {
  //   transform: rotate(0deg);
  // }
  100% {
    transform: rotate(360deg);
  }
}
.renewalUkey {
  padding: 16px 24px 32px;
  .item-box {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #6c737b;
      line-height: 19px;
      margin-bottom: 32px;
      display: flex;
      white-space: nowrap;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #303b50;
        white-space: normal;
      }
    }
  }
  .el-button {
    width: 182px;
    display: block;
    margin: 0 auto;
  }
  .renewaling {
    height: 718px;
    padding-top: 140px;
    text-align: center;
    .icon {
      width: 52px;
      height: 52px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #e4e5e6;
      margin: 0 auto;
      border-radius: 50%;
      padding: 5px;
      img {
        width: 42px;
        height: 42px;
        animation: loading 0.6s linear infinite;
      }
    }
    .tt {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      margin: 24px auto;
    }
    .hint {
      width: 828px;
      background: #f7faff;
      border-radius: 2px;
      line-height: 94px;
      font-size: 16px;
      color: #3a6afe;
      margin: 0 auto;
    }
  }
}
.renewalMatter {
  p {
    font-size: 14px;
    color: #303b50;
    margin-bottom: 32px;
  }
  p:last-child {
    color: #ffb500;
  }
}
</style>